<template>
  <div class="w-full h-screen flex items-center justify-center">
    <div class="w-1/3 bg-bgLight rounded-2xl shadow-2xl login-panel">
      <!-- Login side -->
      <form class="w-full" @submit.prevent="login">
        <div class="bg-bgDarkest text-center py-5 px-8 rounded-t-2xl">
          <h1 class="text-xl">Welcome Back</h1>
          <p class="text-sm">Enter your credentials to access your account.</p>
        </div>
        <div class="py-5 px-8">
          <input-base
            class="w-full mt-4"
            name="username"
            label="Username:"
            type="text"
            placeholder="joe.doe@test.com"
            :showLabel="false"
            :value="username"
            @change="
              (event) => {
                username = event.target.value;
              }
            "
            :icon="['far', 'user']"
          />
          <input-base
            class="w-full mt-4"
            name="password"
            label="Password:"
            type="password"
            placeholder="***********"
            :value="password"
            :showLabel="false"
            @change="
              (event) => {
                password = event.target.value;
              }
            "
            :icon="['fas', 'lock']"
          />
          <button class="btn w-1/2 mt-8 block mx-auto" type="submit">
            Send
          </button>
          <div class="rounded-md border border-red-400 p-4 mt-4" v-if="error">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/x-circle -->
                <svg
                  class="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm text-red-400">
                  {{ error }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import InputBase from '@/components/Base/InputBase';
import { changeSubscriptionToken } from '@/apollo.js';

export default {
  components: {
    InputBase,
  },
  data() {
    return {
      username: null,
      password: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    async login() {
      if (!this.username || !this.password) {
        this.error = 'Fill in your details first';
        return;
      }

      try {
        this.error = null;
        this.loading = true;

        const auth = await axios.post(
          'https://id.redsmart.app/auth/login',
          {
            username: this.username.toLowerCase().trim(),
            password: this.password,
            application: 'enexo',
          },
          { withCredentials: true }
        );

        console.log(auth);

        if (!auth.data) {
          this.error = 'Service Unavailable';
          this.loading = false;
          return;
        }

        if (!auth.data.jwt_token) {
          this.error = 'Authentication Error';
          this.loading = false;
          return;
        }

        if (auth.data.jwt_token) {
          this.$store.dispatch('auth/updateJwt', auth.data);
          changeSubscriptionToken(auth.data.jwt_token);

          return this.$router.push({ path: '/' });
        }
      } catch (e) {
        console.log(e);
        this.error = e;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-panel {
  max-width: 800px;
}
</style>
